@use "~Styles/includes/variables";

:root {
    --page-scroll-amount: 0px;
    --search-term-height: 0px;
    --filter-list-top-offset: 50px;
    --smartbannerTopOffset: 56px;
}

html {
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;

    font-family: variables.$fontPrimary;
}
