@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";
@use "autocomplete_dropdown";

header {
    margin-bottom: 24px;

    @include mixins.min-screen(variables.$md) {
        min-height: 72px;
    }

    &.mobile-open {
        height: 100dvh;
        overflow: auto;

        body.highlighted-content-bar-visible & {
            height: calc(100dvh - var(--smartbannerTopOffset));
        }
    }

    .dropdown-menu {
        max-height: 90dvh;
        padding: 8px 0;

        border-radius: 12px;
        border: 1px solid mixins.get-color(border, transparent, secondary);
        background: mixins.get-color(bg, solid, primary);
        box-shadow: variables.$boxShadowLarge;

        overflow: auto;
    }

    .dropdown-item {
        @include mixins.text-small-medium;
        padding: 6px 20px;
    }

    .vm-button--tertiary {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.vm-navbar {
    position: relative;
    padding: 0 8px;

    @include mixins.min-screen(variables.$sm) {
        max-width: 540px;
        margin: 0 auto;
        padding: 0;
    }

    @include mixins.min-screen(variables.$smd) {
        max-width: 720px;
    }

    @include mixins.min-screen(variables.$md) {
        max-width: 960px;
    }

    @include mixins.min-screen(variables.$xl) {
        max-width: 1140px;
    }

    @include mixins.min-screen(variables.$xxl) {
        max-width: 1240px;
    }

    .vm-button {
        &--sm {
            font-weight: 500;
        }

        .icon {
            font-weight: 700;
        }
    }

    .dropdown-menu {
        right: 30px;
        box-shadow: variables.$boxShadow2XLarge;

        @include mixins.min-screen(variables.$md) {
            right: unset;
        }
    }

    &__brand {
        margin-bottom: 12px;

        @include mixins.min-screen(variables.$md) {
            position: absolute;
            left: 0;
            top: 8px;

            display: flex;
            align-items: center;
        }

        img {
            width: auto;
            height: 20px;
            margin: 18px 16px;
        }
    }

    &__toggle,
    &__search-toggle {
        display: block;

        position: absolute;
        top: 8px;

        width: 40px;
        height: 40px;

        font-size: 20px;
        line-height: 40px;
        text-align: center;

        cursor: pointer;

        &,
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: mixins.get-color(text, tertiary);
        }

        &:focus {
            outline: 1px dotted mixins.get-color(bg, transparent, tertiary);
        }

        @include mixins.min-screen(variables.$md) {
            display: none;
        }
    }

    &__toggle {
        right: 14px;
    }

    &__search-toggle {
        right: 58px;

        &.opened {
            right: 14px;
        }
    }

    &__search {
        position: relative;
        display: block;
        padding: 4px 16px 12px 16px;

        @include mixins.min-screen(variables.$md) {
            width: 250px;
            margin: 0 auto;
            padding: 12px 0;
        }

        @include mixins.min-screen(variables.$xl) {
            width: 400px;
        }

        input[type="search"] {
            display: block;
            width: 100%;
            height: 48px;
            padding: 4px 76px 4px 24px;

            border-radius: 100px;
            border: 1.5px solid mixins.get-color(border, transparent, secondary);
            background: mixins.get-color(bg, solid, primary);

            appearance: none;

            @include mixins.text-medium-regular;
            @include mixins.min-screen(variables.$md) {
                box-shadow: variables.$boxShadowXSmall;
            }

            &:focus {
                outline: none;
            }
        }

        button[type="submit"],
        button[type="button"] {
            position: absolute;
            top: 8px;

            width: 40px;
            height: 40px;

            text-align: center;
            line-height: 40px;
            border: none;
            border-radius: 100%;

            @include mixins.min-screen(variables.$md) {
                top: 16px;
            }
        }

        button[type="submit"] {
            right: 20px;
            color: mixins.get-color(bg, solid, primary);
            background: mixins.get-color(accent, primary);

            @include mixins.min-screen(variables.$md) {
                right: 4.5px;
            }
        }

        button[type="button"] {
            right: 64px;
            color: mixins.get-color(text, primary);
            background: transparent;

            @include mixins.min-screen(variables.$md) {
                right: 48.5px;
            }
        }
    }

    &__login {
        position: relative;

        display: flex;
        justify-content: flex-end;
        gap: 8px;
        width: 100%;
        margin-bottom: 16px;

        @include mixins.min-screen(variables.$sm) {
            position: absolute;
            right: 0;
            top: 8px;

            max-width: 500px;
            margin-bottom: 0;
        }

        a {
            position: absolute;
            top: 100%;
            right: 0;

            @include mixins.text-xs-regular;
        }

        .vm-button {
            width: 86px;
        }

        .form-control {
            width: 180px;
        }
    }

    &__profile {
        position: relative;

        @include mixins.min-screen(variables.$md) {
            position: absolute;
            right: 0;
            top: 8px;

            display: flex;
            align-items: center;
        }

        #navbar-profile-dropdown {
            position: relative;
            margin: 8px 0 8px 12px;
            padding-left: 0;

            @include mixins.min-screen(variables.$md) {
                padding-right: 0;

                .icon {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    background: mixins.get-color(bg, solid, primary);
                    border-radius: 100%;
                    box-shadow: variables.$boxShadowXSmall;
                }
            }
        }
    }

    &__profile-name {
        @include mixins.min-screen(variables.$md) {
            display: none;
        }
    }
}

.vm-terms-nav {
    margin: 0;
    padding: 8px 12px 20px 12px;

    border-top: 1px solid mixins.get-color(border, transparent, primary);
    border-bottom: 1px solid mixins.get-color(border, transparent, primary);
    background: mixins.get-color(bg, solid, secondary);

    list-style-type: none;

    @include mixins.min-screen(variables.$md) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        padding-bottom: 8px;
    }

    .dropdown-menu {
        right: 15px;

        @include mixins.min-screen(variables.$md) {
            right: unset;
        }
    }

    .vm-button {
        &--sm {
            font-weight: 500;
            color: mixins.get-color(text, tertiary);
        }

        .icon {
            font-weight: 700;
        }
    }

    .nav-item {
        position: relative;
        margin: 0 auto;

        @include mixins.min-screen(variables.$sm) {
            max-width: 540px;
        }

        @include mixins.min-screen(variables.$smd) {
            max-width: 720px;
        }

        @include mixins.min-screen(variables.$md) {
            margin: 0;
        }
    }

    .nav-item-disease {
        display: none;
        padding-right: 16px;
        border-right: 1px solid mixins.get-color(border, transparent, primary);

        @include mixins.min-screen(variables.$md) {
            display: list-item;
        }

        a {
            display: block;
            padding: 6px 12px;

            border: 1.5px solid mixins.get-color(border, solid, secondary);
            border-radius: 30px;
            background-color: mixins.get-color(bg, solid, primary);

            @include mixins.text-small-medium;

            color: mixins.get-color(text, primary);

            &:hover,
            &:active {
                border-color: mixins.get-color(border, solid, quaternary);
                text-decoration: none;
            }

            &:active {
                background-color: mixins.get-color(button, secondary, bg, pressed);
            }

            .icon {
                display: inline-block;
                margin-left: 4px;
                font-size: 16px;
                line-height: 20px;
                vertical-align: bottom;
            }
        }
    }
}

/**
 * Alternative container class used for public pages, accounts_login and site_root
 */
.site-header {
    margin: 0 3rem;
}

@include mixins.max-screen(variables.$sm - 1) {
    .site-header {
        margin: 0;
    }
}

/**
 * Pages
 */
.manufacturer-channel-page {
    header {
        margin-bottom: 0;
    }
}

.video-page {
    header {
        margin-bottom: 0;

        @include mixins.min-screen(variables.$md) {
            margin-bottom: 24px;
        }
    }
}

.topics-page {
    header {
        margin-bottom: 48px;
    }
  
    @include mixins.max-screen(variables.$md - 1) {
        header,
        .secondary-nav {
            margin-bottom: 0;
        }
    }
}

.video-page {
    header {
        div.container {
            @include mixins.max-screen(variables.$md - 1) {
                max-width: none;
            }
        }
    }
}

.event-page {
    header {
        margin-bottom: 48px;
    }
}

.channel-page {
    header {
        margin-bottom: 48px;
    }

    &.lecture-series-detail-page {
        header {
            margin-bottom: 16px;
        }
    }
}

.channel-detail-page {
    header {
        div.container {
            @include mixins.max-screen(variables.$md - 1) {
                max-width: none;
            }
        }
    }
}

.lecture-series-item-detail-page {
    header {
        div.container {
            @include mixins.max-screen(variables.$md - 1) {
                max-width: none;
            }
        }
    }
}

.search-page {
    header {
        margin-bottom: 48px;
    }
    
    @include mixins.max-screen(variables.$md - 1) {
        header,
        .secondary-nav {
            margin-bottom: 0;
        }
    }
}

.webinar-page {
    header {
        margin-bottom: 48px;
    }
}

.heart-beat-page {
    header {
        margin-bottom: 48px;
    }
}
