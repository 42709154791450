@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";

body {
    @include mixins.min-screen(variables.$md) {
        padding-bottom: 301px;
    }

    @include mixins.min-screen(variables.$xl) {
        padding-bottom: 198px;
    }
}

.footer {
    padding: 40px 24px 24px;
    border-top: 1px solid mixins.get-color(border, transparent, primary);
    background-color: mixins.get-color(bg, secondary);
    margin: 40px 0 0;

    @include mixins.min-screen(variables.$md) {
        padding: 40px 15px 24px;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @include mixins.min-screen(variables.$xl) {
        padding: 32px 15px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;

        @include mixins.min-screen(variables.$smd) {
            column-gap: 24px;
        }

        @include mixins.min-screen(variables.$md) {
            max-width: 960px;
            margin: 0 auto;
        }

        @include mixins.min-screen(variables.$xl) {
            max-width: 1140px;
        }

        @include mixins.min-screen(variables.$xxl) {
            max-width: 1240px;
        }
    }

    &__nav-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        width: calc((100% - 24px) / 2);

        @include mixins.min-screen(variables.$smd) {
            order: 2;
            width: calc((100% - 48px) / 3);
        }

        @include mixins.min-screen(variables.$xl) {
            flex-direction: row;
            justify-content: flex-end;
            column-gap: 24px;
            width: 100%;
            margin: 0 0 -24px;
            transform: translateY(-20px);
        }
    }

    &__legal-nav-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        width: calc((100% - 24px) / 2);
        margin: 0 0 0 24px;

        @include mixins.min-screen(variables.$smd) {
            order: 3;
            width: calc((100% - 48px) / 3);
            margin: 0;
        }

        @include mixins.min-screen(variables.$xl) {
            flex-direction: row;
            column-gap: 24px;
            order: 3;
            width: 100%;
            margin: 13px 0 0;
        }
    }

    &__nav-link {
        font-family: variables.$fontQuaternary;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.07px;
        color: mixins.get-color(text, quaternary);

        &:hover {
            color: mixins.get-color(text, quaternary);
            text-decoration: underline;
        }

        &--accented {
            color: mixins.get-color(accent, primary);

            &:hover {
                color: mixins.get-color(accent, primary);
            }
        }
    }

    &__logo-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        flex: 1 0 100%;
        margin: 40px 0 0;

        @include mixins.min-screen(variables.$smd) {
            position: relative;
            z-index: 1;
            flex: 0 0 calc((100% - 48px) / 3);
            align-items: flex-start;
            margin: 0;
        }

    }

    &__logo {
        width: 105px;
        height: 20px;
    }

    &__copyright {
        margin: 0;
        font-family: variables.$fontQuaternary;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.07px;
        color: mixins.get-color(text, tertiary);

        span {
            @include mixins.screen(variables.$smd, variables.$md - 1) {
                display: block;
                width: 100%;
            }
        }
    }
}
