@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";

.vm-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    border-radius: 100px;
    font-family: variables.$fontQuaternary;
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: all 0.25s;
    text-decoration: none;

    &:focus {
        outline: none !important;
    }

    &:hover {
        text-decoration: none !important;
    }

    &--hidden {
        display: none !important;
        visibility: hidden !important;
    }

    &--primary {
        @include mixins.button-style(primary);
    }

    &--secondary {
        @include mixins.button-style(secondary);
        border: 1.5px solid mixins.get-color(border, solid, secondary);

        &:hover,
        &.is-hover,
        &.vm-button--hover,
        &:active,
        &.is-active,
        &.vm-button--active {
            border-color: mixins.get-color(border, solid, quaternary);
        }
    }

    &--tertiary {
        @include mixins.button-style(tertiary);
    }

    &--danger {
        @include mixins.button-style(danger);
    }

    &--min-width {
        min-width: 120px;
    }

    &--xs {
        gap: 4px;
        height: 32px;
        padding: 7px 12px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    &--sm {
        gap: 6px;
        height: 40px;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    &--md {
        gap: 8px;
        height: 48px;
        padding: 14px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.08px;
    }

    &--lg {
        gap: 8px;
        height: 56px;
        padding: 18px 20px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.08px;
    }

    &--xl {
        gap: 8px;
        height: 64px;
        padding: 22px 24px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.08px;
    }

    // styles for custom buttons

    &--toggle-autoplay {
        margin-left: 4px;
        border-radius: 3px;
    }

    &--video-contact {
        width: 100%;
        text-transform: uppercase;
        border-radius: 0;
        font-size: 12px;

        @include mixins.screen(variables.$sm, variables.$xl - 1) {
            .btn-video-contact {
                width: calc(100% - 2px);
                margin: 0 1px;
            }
        }
    }

    &--pagination {
        width: 40px;
        height: 40px;
        padding: 10px;
        font-weight: 500;
    }
}
