@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";

.profile-image {
    display: block;
    width: 40px;
    height: 40px;

    text-align: center;
    line-height: 40px;
    border-radius: 100%;

    overflow: hidden;

    &.large {
        width: 48px;
        height: 48px;
        line-height: 48px;
    }

    a {  
        text-decoration: none;
    }

    .text-box,
    img {
        display: block;
        width: 100%;
        height: auto;
    }

    .text-box {
        // Both background color and text styles are not defined as a component in Figma
        font-family: variables.$fontPrimary;
        font-size: 21.6px;
        font-style: normal;
        font-weight: 600;
        color: mixins.get-color(text, inverse);
        background: mixins.get-color(neutral, teal-900);
    }
}
