@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";

.horizontal_tabs {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;

    &__item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 16px;
        border-radius: 9999px;
        border: 1px solid rgba(#000, 0.24);
        background-color: mixins.get-color(bg, transparent, tertiary);
        font-family: variables.$fontPrimary;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.07px;
        color: mixins.get-color(text, primary);
        appearance: none;
        outline: none;
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
            background-color: mixins.get-color(bg, transparent, quaternary);
        }

        &:active,
        &:focus {
            outline: none;
        }

        &.is-active {
            border-color: mixins.get-color(neutral, 800);
            background-color: mixins.get-color(neutral, 800);
            color: mixins.get-color(text, inverse);

            &:hover {
                background-color: mixins.get-color(neutral, 700);
            }
        }
    }
}
