@use "~Styles/includes/mixins";
@use "~Styles/includes/variables";

.autocomplete {
    &__items {
        position: absolute;
        top: 100%;
        left: -12px;
        right: -12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        
        height: calc(100dvh - 120px);
        padding: 0px 12px;

        background: mixins.get-color(bg, solid, primary);

        z-index: 99;

        @include mixins.min-screen(variables.$md) {
            top: calc(100% - 8px);
            left: 0;
            right: 0;
            height: unset;
            padding: 8px 0;

            border: 1px solid mixins.get-color(border, transparent, secondary);
            border-radius: 12px;
            box-shadow: variables.$boxShadow2XLarge;
        }
    }

    &__item {
        display: flex;
        margin: 0 12px;
        padding: 11px 24px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        @include mixins.text-small-medium;
        color: mixins.get-color(text, secondary);

        @include mixins.min-screen(variables.$md) {
            margin: 0;
            border-bottom: none !important;
        }

        &.active,
        &:hover {
            background-color: mixins.get-color(bg, solid, secondary);
            cursor: pointer;
        }

        &:not(:last-child) {
            border-bottom: 1px solid mixins.get-color(divider);
        }
    }

    &__item-icon {
        line-height: 20px;
        color: mixins.get-color(text, quaternary);
    }
}
